<template>
  <div class="space-page d-flex flex-column fill-height">
    <PageHeader>
      <div class="d-flex align-center">
        <UiBtn icon class="mr-1" :to="{name: 'Dashboard'}" color="gray-60" exact>
          <IconArrowLeft width="16" style="margin-top: 2px;"/>
        </UiBtn>
        <h1 class="text-heading"> {{
            duplicatingSpace ? `Duplicating Space "${duplicatingSpace.title}"` : 'Create New Space'
          }}</h1>
      </div>
    </PageHeader>

    <v-container>
      <v-stepper flat class="mt-3" :value="currentRouteName">
        <v-stepper-header class="justify-start mb-10">
          <v-stepper-step
            v-for="(step, idx) in steps"
            :key="step.routeName"
            :step="step.routeName"
            :editable="!!space"
            :complete="isCompletedStep(idx)"
            @click="!!space ? goTo(step.routeName) : null"
            class="py-0 px-4"
          >
            {{ step.stepTitle }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <component :is="currentRouteName" :space="space"/>
    </v-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader";
import UiBtn from "@/components/UI/UiBtn";
import {mapGetters} from "vuex";

export default {
  name: "CreateSpacePage",
  components: {
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    SpaceCreateView: () => import('@/views/spaces/create/SpaceCreateView'),
    SpaceAddMembersView: () => import('@/views/spaces/edit/SpaceMembersView'),
    CreateSpaceWorkflowView: () => import('@/views/spaces/edit/SpaceWorkflowView'),
    SpaceCreateViewGeneral: () => import('@/views/spaces/edit/SpaceGeneralView'),
    UiBtn,
    PageHeader
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters([
      'getSpaceById',
      'getSpaces'
    ]),
    isCompletedStep() {
      return index => {
        const selectedStepIdx = this.steps.findIndex(step => step.routeName === this.currentRouteName)
        return index < selectedStepIdx
      }
    },
    space() {
      return this.getSpaceById(this.$route.params.id)
    },
    duplicatingSpace() {
      return this.getSpaces.find(space => space.id === +this.$route.query.duplicate_from) || null
    },
    steps() {
      return [
        {
          routeName: this.space ? 'SpaceCreateViewGeneral' : 'SpaceCreateView',
          stepTitle: 'Name your Space',
        },
        {stepTitle: 'Members', routeName: 'SpaceAddMembersView'},
        {stepTitle: 'Workflow', routeName: 'CreateSpaceWorkflowView'},
      ]
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
    async goTo(name) {
      try {
        await this.$router.push({name, query: this.$route.query})
      } finally {
        this.loading = false
      }
    }
  }
}
</script>


<style scoped lang="scss">
:deep(.v-stepper) {
  padding: 8px 8px;
  margin-left: -8px;
  margin-top: -8px;
  margin-right: -8px;

  .v-stepper__items,
  .v-stepper__wrapper {
    overflow: initial;
  }
}

.v-stepper__header {
  height: auto;
  box-shadow: none;

  :deep(.v-stepper__step) {
    width: 25%;
    height: 30px;
    border-bottom: 3px solid var(--v-gray-30-base);
    border-radius: 5px 5px 0 0;

    &.v-stepper__step--complete {
      border-bottom-color: var(--v-success-base);
    }

    .v-stepper__step__step {
      display: none !important;
    }

    .v-stepper__label {
      display: block !important;
      font-size: 12px;
      font-weight: 400;
      text-shadow: none !important;
      color: var(--v-gray-60-base);
    }

    &.v-stepper__step--active {
      border-bottom: 3px solid var(--v-accent-base) !important;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

      .v-stepper__label {
        color: var(--v-gray-60-base) !important;
      }
    }
  }
}
</style>
