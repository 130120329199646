<template>
  <div class="space-page d-flex flex-column fill-height" v-if="space">
    <PageHeader>
      <div class="d-flex justify-space-between align-center">
        <h1 class="d-flex align-center text-title-1 overflow-hidden">
          <UiBtn icon class="mr-1" color="gray-60" :to="{name: 'Dashboard'}">
            <IconArrowLeft width="16"/>
          </UiBtn>
          <v-tooltip top color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <span class="text-truncate" v-bind="attrs"
                    v-on="on">{{ space.title }}</span>
            </template>
            <div class="text-captions-1">
              {{ space.title }}
            </div>
          </v-tooltip>
          <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
          <span>Settings</span>
          <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
          <span class="accent--text">{{ currentTab.label }}</span>
        </h1>
      </div>
    </PageHeader>

    <v-container>
      <v-tabs
        v-model="tab"
        slider-color="accent"
        slider-size="3"
        height="32"
        class="tabs mt-8 mb-10"
      >
        <v-tab
          v-for="page in pages"
          :key="page.routeName"
          :to="{name: page.routeName}"
          :ripple="false"
          class="tab relative text-captions-1 text-capitalize justify-start"
        >
          {{ page.label }}
        </v-tab>
      </v-tabs>

      <component :is="$route.name" :space="space"/>
    </v-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader";
import UiBtn from "@/components/UI/UiBtn";
import {mapGetters} from "vuex";

export default {
  name: "EditSpacePage",
  components: {
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    SpaceGeneralView: () => import('@/views/spaces/edit/SpaceGeneralView'),
    SpaceMembersView: () => import('@/views/spaces/edit/SpaceMembersView'),
    SpaceWorkflowView: () => import('@/views/spaces/edit/SpaceWorkflowView'),
    UiBtn,
    PageHeader
  },
  data() {
    return {
      tab: null,
      pages: [
        {label: 'General', routeName: 'SpaceGeneralView'},
        {label: 'Members', routeName: 'SpaceMembersView'},
        {label: 'Workflow', routeName: 'SpaceWorkflowView'},
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getSpaceById'
    ]),
    space() {
      return this.getSpaceById(this.$route.params.id)
    },
    currentTab() {
      return this.pages.find(page => page.routeName === this.$route.name)
    }
  },
  methods: {
    // selectStep(stepName) {
    //   const prevStep = this.steps.find(step => step.stepName === this.selectedStep)
    //   const selectedStep = this.steps.find(step => step.stepName === stepName)
    //   this.selectedStep = stepName
    //   prevStep.complete = true
    //   selectedStep.complete = true
    // }
  },
  created() {
    if (!this.space) {
      this.$router.replace({name: 'NotFoundPage'})
    }
  }
}
</script>


<style scoped lang="scss">
:deep(.v-slide-group__wrapper) {
  overflow: initial;
  contain: initial;
}

:deep(.tabs) {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }
}

.tab {
  height: 30px;
  padding: 0 44px 0 16px;
  color: var(--v-gray-60-base) !important;
  border-radius: 5px 5px 0 0;
  min-width: 150px;


  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 7px;
  }

  &::before {
    border-radius: 5px 5px 0 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 3px;
    background-color: var(--v-gray-30-base);
  }

  &.v-tab--active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    color: var(--v-gray-60-base) !important;
  }
}
</style>
